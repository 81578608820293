import React, { useEffect } from 'react'
import * as S from './styled'
import firebase from 'firebase/app'

import { Statuses } from '@app/courses/domain/ratingStatus'
import useUser from '@common/hooks/useUser'
import {
  OPEN_EVALUATION_FAILURE,
  resetCourseEvaluation,
} from '@app/evaluation-core/evaluation'
import { useSnackbar } from '@app/snackbar'
import { LRClub } from '../../../types/Clubs'
import CourseListItem from './CourseListItem'
import { useApi } from '@common/hooks/useApi'
import { MiProjectBranch, ProjectCourse } from '../../../types/Project'
import { navigate } from '@app/navigation'
import { useAxios } from '@common/hooks/useAxios'
import { RatingTemplate } from '../../../types/Templates'
import { InLineSpinner } from '@app/evaluation/components/Spinner'
import { getClubStatus } from '@app/courses/domain/ratingStatus2'
import { Eval, MiProjectStatus } from '@app/evaluation-core/types'
import { FBREF_COURSE_EVALUATIONS } from '@app/evaluation-core/refs'
import { useFirebaseConnect } from 'react-redux-firebase'
import useFormattedEvaluations from '../../../pages/AdminPage/useFormattedEvaluations'
import { useEvaluation } from '@common/hooks'
import { EvaluationDoc } from '../../../pages/CompositeCoursePage/CompositeCoursePage'

type Props = {
  club: LRClub
}

const CourseList: React.FC<Props> = ({ club }) => {
  const axios = useAxios('/ratings/')
  const { data, isLoading } = useApi<MiProjectBranch>(
    `/courses/projects/${club.id}`
  )

  // const { data: rateableEvaluations, isLoading: evalsLoading } = useApi<
  //   EvaluationDoc[]
  // >(`/evaluations/import?holeId=${holeIds}&clubId=${club.id}`, undefined, {
  //   refreshInterval: 5000,
  // })

  const courses = data?.courses ?? []

  const [openSnackbar] = useSnackbar()

  function isRateable(item: ProjectCourse) {
    return !item.tees.every((tee) => tee.playerCategory === '0')
  }

  async function createMappedRatingTemplate(course: ProjectCourse) {
    const holeIds = course.holes.map((hole) => hole.physicalID)
    const templateBody = {
      courseId: course.id,
      clubId: club.id,
      name: course.name,
      holeIds,
      defaultCourseSettings: {
        bunkDepthDefault: 0,
        grassType: 'cool',
        intermediateCut: 0,
        measurement: 'imperial',
        roughHeight: 3,
        stimpFeet: 9,
        stimpInches: 0,
        'wind_1-9': 0,
        'wind_10-18': 0,
      },
      mapped: true,
    }

    let templateId: string
    try {
      const { data } = await axios.get<RatingTemplate>(
        `/templates?holeIds=${holeIds.join(',')}`
      )
      templateId = data.id
    } catch (error) {
      const { data } = await axios.post<RatingTemplate>(
        '/templates',
        templateBody
      )
      templateId = data.id
    }
    navigate('CreateRating', {
      holeIds,
      templateId,
      club,
      branchId: data?.projectBranchID,
      isRateable: isRateable(course),
    })
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <InLineSpinner size={32} />
      </div>
    )
  }

  if (!data || (data?.courses.length <= 0 && !isLoading)) {
    return <span>No mapped courses exist for this golf club.</span>
  }

  const checkClubStatus = (cb: () => void) => () => {
    const clubStatus = getClubStatus(club.branchStatus)
    if (clubStatus !== Statuses.READY_FOR_RATING) {
      return openSnackbar(
        'The club is not ready to rate. Please refer to LiveCR.'
      )
    }
    cb()
  }

  return (
    <S.TableContainer>
      <S.Table>
        <S.TableRow>
          <S.TableHeadCol style={{ width: '20%' }}>Name</S.TableHeadCol>
          <S.TableHeadCol style={{ width: '10%' }}>Status</S.TableHeadCol>
          <S.TableHeadCol style={{ width: '10%' }}>
            Last finalized
          </S.TableHeadCol>
          <S.TableHeadCol style={{ width: '10%' }}>
            LiveCR Last Synced
          </S.TableHeadCol>
          <S.TableHeadCol style={{ width: '5%' }}>Holes</S.TableHeadCol>
          <S.TableHeadCol style={{ width: '25%' }}>Tees</S.TableHeadCol>
          <S.TableHeadCol
            style={{ width: 'auto', marginLeft: 'auto' }}
          ></S.TableHeadCol>
        </S.TableRow>

        <S.TableBody className="courses-list">
          {courses
            .sort((a, b) => +a.name - +b.name)
            .map((course) => {
              return (
                <CourseListItem
                  key={'course' + course.id}
                  courseRateable={isRateable(course)}
                  hasActiveEval={false}
                  projectCourse={course}
                  lrCourse={club?.courses?.find((c) => c.id === course.id)}
                  projectStatus={
                    club.projectStatus &&
                    (club.projectStatus.toString() as MiProjectStatus)
                  }
                  startEvaluation={checkClubStatus(() =>
                    createMappedRatingTemplate(course)
                  )}
                  buttonText={'Manage Ratings'}
                />
              )
            })}
        </S.TableBody>
      </S.Table>
    </S.TableContainer>
  )
}
export default CourseList
