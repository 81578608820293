import React from 'react'
import * as S from './styled'

import {
  resetEvaluationRatingRevision,
  startEvaluation,
  viewEvaluationFn,
} from '@app/evaluation-core/evaluation'
import FeatherIcon from '@ovaeasy/react-native-vector-icons/Feather'
import { LRClub } from '../../../types/Clubs'
import { InLineSpinner } from '@app/evaluation/components/Spinner'
import {
  EvaluationDoc,
  EvaluationDocWithForms,
} from '../../../pages/CreateRatingPage/CreateRatingPage'

import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import {
  EvalStatus,
  evaluationButtonText,
} from '@app/courses/domain/ratingStatus'
import FinalRatingItem from './FinalRatingItem'
import { MiProjectBranch } from '../../../types/Project'
import { useApi } from '@common/hooks/useApi'
import { useAxios } from '@common/hooks/useAxios'
import { useSnackbar } from '@app/snackbar'
import { useTheme } from 'styled-components'
import { Theme } from '@app/ui/theme'
import useUser from '@common/hooks/useUser'
import TwoButtonModal from '../../organisms/TwoButtonModal'
import DateTimeView from '../../atoms/DateTimeView'
import RoundedDeleteButton from '@app/ui/rounded-button/RoundedDeleteButton'
import RatingListInfoDropdown from '../RatingListInfoDropdown'
import HideOnTablet from './HideOnTablet'
import {
  Eval,
  Evaluation,
  EvaluationUser,
  MIHole,
  TeamRole,
} from '@app/evaluation-core/types'
import EvalStatusIndicator from '../CoursesList/EvalStatusIndicator'
import { EvaluationsListActionMenu } from '@app/evaluation/components/EvaluationsListActionMenu'

type Props = {
  club: LRClub
  evaluations: EvaluationDoc[] | EvaluationDocWithForms[] | undefined
  realTimeEvals?: Record<string, Eval>
  loading: boolean
  templateId?: string
  noDataText: string
  deleteAction?: (evaluationId: string) => Promise<void>
}

const convertStatusToText = (status?: number) => {
  switch (true) {
    case status === EvalStatus.NOT_STARTED:
      return 'Not Started'
    case status === EvalStatus.PREPARING:
      return 'Preparing'
    case status === EvalStatus.IN_PROGRESS:
      return 'In Progress'
    case status === EvalStatus.FINALIZED:
      return 'Finalized'
    default:
      return 'Unknown'
  }
}

const RTEvaluationsList: React.FC<Props> = ({
  evaluations,
  realTimeEvals,
  deleteAction,
  loading,
  club,
  noDataText,
  templateId,
}) => {
  const axios = useAxios('/ratings')
  const theme = useTheme() as Theme
  const user = useUser()
  const [showId, setShowId] = React.useState(false)
  const [selectedEval, setSelectedEval] = React.useState<string>('')
  const [
    showDeleteEvaluationConfirmModalVisible,
    setShowDeleteEvaluationConfirmModalVisible,
  ] = React.useState(false)
  const [
    showResetEvaluationRatingRevisionConfirmModalVisible,
    setShowResetEvaluationRatingRevisionConfirmModalVisible,
  ] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [createRatingLoading, setCreateRatingLoading] = React.useState(false)
  const [openSnackbar] = useSnackbar({
    style: {
      backgroundColor: theme.colors.warning,
    },
  })

  const { data, isLoading } = useApi<MiProjectBranch>(
    `/courses/projects/${club.id}`
  )

  if (loading || isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <InLineSpinner size={32} />
      </div>
    )
  }

  if (
    !realTimeEvals ||
    (Object.values(realTimeEvals)?.length <= 0 && !loading)
  ) {
    return <span>{noDataText}</span>
  }

  function isEvaluationWithForms(
    evaluation: EvaluationDoc
  ): evaluation is EvaluationDocWithForms {
    return (evaluation as EvaluationDocWithForms)?.finalizedForms !== undefined
  }

  const createRating = async (evaluationId: string) => {
    if (createRatingLoading) {
      return openSnackbar(
        <div>
          <span>Please wait for the previous rating to be generated.</span>
        </div>
      )
    }
    setCreateRatingLoading(true)
    if (!templateId) return
    try {
      await axios.post<void>(`/templates/${templateId}/generate`, {
        evaluationId,
      })
    } finally {
      setCreateRatingLoading(false)
    }
  }

  const deleteEvaluationFn = async (evaluationId: string) => {
    if (isDeleting) return
    setIsDeleting(true)
    if (typeof deleteAction !== 'function') return
    try {
      await deleteAction(evaluationId)
    } finally {
      setIsDeleting(false)
    }
    if (showDeleteEvaluationConfirmModalVisible) {
      setShowDeleteEvaluationConfirmModalVisible(false)
    }
  }

  const resetEvaluationRatingRevisionFn = async (evaluationId: string) => {
    await resetEvaluationRatingRevision(evaluationId)

    if (showResetEvaluationRatingRevisionConfirmModalVisible) {
      setShowResetEvaluationRatingRevisionConfirmModalVisible(false)
    }
  }

  const startEvaluationFn = async (evaluation: EvaluationDoc) => {
    try {
      await startEvaluation(
        +club.id,
        club.branchID,
        evaluation.holeIds,
        {
          ...evaluation.ratingRevision,
          parentEvaluation: evaluation.id,
        },
        evaluation
      )
    } catch (err) {
      let msg = err

      if (err === 'not_member' || err === 'not_member_revision')
        msg = 'You are not member of this rating and cannot open it!'

      openSnackbar(msg)
    }
  }

  const showDeleteEvaluationConfirmationModal = (evaluationId: string) => {
    setShowDeleteEvaluationConfirmModalVisible(true)
    setSelectedEval(evaluationId)
  }

  const showResetEvaluationRatingRevisionConfirmationModal = (
    evaluationId: string
  ) => {
    setShowResetEvaluationRatingRevisionConfirmModalVisible(true)
    setSelectedEval(evaluationId)
  }

  const getFSTeamLeader = (evaluation: any) =>
    evaluation?.users?.find((user) => user.role === 1)?.email ||
    evaluation?.users?.find((user) => user.role === 0)?.email

  const getRTTeamLeader = (evaluation: Eval) => {
    const entries = Object.values(evaluation?.users)

    return (
      entries.find((evalUser) => evalUser.role === TeamRole.LEADER)?.view
        ?.email ||
      entries.find((evalUser) => evalUser.role === TeamRole.LEADER)?.view?.email
    )
  }

  const hasFinalized = evaluations?.some((e) => e.finalizedAt)

  const filterHolesByLockedStatus = (evaluation?: Eval) =>
    evaluation?.holes?.reduce(
      (acc, hole) => {
        if (hole.locked !== 0) {
          acc.lockedHoles.push(hole)
        } else {
          acc.unlockedHoles.push(hole)
        }
        return acc
      },
      { lockedHoles: [] as MIHole[], unlockedHoles: [] as MIHole[] }
    ) || { lockedHoles: [], unlockedHoles: [] }

  return (
    <S.TableContainer>
      <thead>
        <S.TableRowHead onClick={() => user.isAdmin && setShowId(!showId)}>
          <S.TableHeadCol style={{ width: '15%', minWidth: '100px' }}>
            {showId ? 'ID' : 'Name'}
          </S.TableHeadCol>
          {hasFinalized && (
            <HideOnTablet>
              <S.TableHeadCol style={{ width: '10%', minWidth: '160px' }}>
                Finalized Date
              </S.TableHeadCol>
            </HideOnTablet>
          )}
          {!hasFinalized && (
            <HideOnTablet>
              <S.TableHeadCol style={{ width: '10%', minWidth: '160px' }}>
                Rating Status
              </S.TableHeadCol>
            </HideOnTablet>
          )}
          <S.TableHeadCol style={{ width: '10%', minWidth: '160px' }}>
            Rating Date
          </S.TableHeadCol>
          <HideOnTablet>
            <S.TableHeadCol style={{ width: '15%' }}>
              Team Leader
            </S.TableHeadCol>
          </HideOnTablet>
          <S.TableHeadCol style={{ width: '5%', minWidth: '50px' }}>
            Revision
          </S.TableHeadCol>
          <S.TableHeadCol style={{ width: '5%', minWidth: '50px' }}>
            {!hasFinalized ? 'Holes\nlocked' : 'Holes'}
            {/* TODO Line break not working / Stoffe */}
          </S.TableHeadCol>
          <HideOnTablet>
            <S.TableHeadCol style={{ width: '5%' }}>
              Manual Version
            </S.TableHeadCol>
          </HideOnTablet>
          <S.TableHeadCol style={{ width: '30%' }} />
        </S.TableRowHead>
      </thead>
      <tbody>
        {realTimeEvals &&
          Object.values(realTimeEvals)
            ?.filter((e) => e.clubId == Number(club.id))
            .map((realTimeEval, i) => {
              const evaluation =
                evaluations &&
                evaluations.find((ed) => ed.id == realTimeEval?.id)

              //if (!evaluation) return

              const { lockedHoles, unlockedHoles } =
                filterHolesByLockedStatus(realTimeEval)

              // TODO Implement this
              const canDelete = false

              return (
                <React.Fragment
                  key={`${realTimeEval?.saveName || evaluation?.saveName}-${i}`}
                >
                  <S.TableRow>
                    <S.TableCol>
                      {showId
                        ? realTimeEval?.id
                        : realTimeEval?.saveName || evaluation?.saveName || '-'}
                    </S.TableCol>
                    {!hasFinalized && (
                      <HideOnTablet>
                        <S.TableCol>
                          <EvalStatusIndicator
                            status={realTimeEval?.status ?? evaluation?.status}
                            finalizedAt={evaluation?.finalizedAt}
                          />
                        </S.TableCol>
                      </HideOnTablet>
                    )}
                    {hasFinalized && (
                      <HideOnTablet>
                        <S.TableCol>
                          <DateTimeView dateString={evaluation?.finalizedAt} />
                        </S.TableCol>
                      </HideOnTablet>
                    )}
                    <S.TableCol>
                      <DateTimeView
                        dateString={
                          realTimeEval?.evaluationDate ||
                          evaluation?.ratingRevision.ratingDate
                        }
                        showTime={false}
                      />
                    </S.TableCol>
                    <HideOnTablet>
                      <S.TableCol>
                        {realTimeEval
                          ? getRTTeamLeader(realTimeEval)
                          : getFSTeamLeader(evaluation)}
                      </S.TableCol>
                    </HideOnTablet>
                    <S.TableColEnd>
                      <S.ClubHoleCount
                        style={{ borderRadius: '8px', margin: '0px' }}
                      >
                        {evaluation?.status === EvalStatus.FINALIZED ||
                        realTimeEval?.ratingRevision?.ratingRevision === null
                          ? evaluation?.ratingRevision?.ratingRevision
                          : realTimeEval?.ratingRevision?.ratingRevision ??
                            evaluation?.ratingRevision?.ratingRevision}
                      </S.ClubHoleCount>
                    </S.TableColEnd>
                    <S.TableColEnd>
                      <S.ClubHoleContainer>
                        {unlockedHoles.length === 0 &&
                        lockedHoles.length === 0 ? (
                          <S.LockedHolesCount>
                            {evaluation?.holeIds.length}
                          </S.LockedHolesCount>
                        ) : (
                          <>
                            <S.LockedHolesCount>
                              {lockedHoles.length}/{evaluation?.holeIds.length}
                            </S.LockedHolesCount>
                          </>
                        )}
                      </S.ClubHoleContainer>
                    </S.TableColEnd>
                    <HideOnTablet>
                      <S.TableCol>
                        {(evaluation?.status === EvalStatus.FINALIZED
                          ? evaluation?.manualVersion
                          : realTimeEval?.manualVersion ||
                            evaluation?.manualVersion) || '-'}
                      </S.TableCol>
                    </HideOnTablet>
                  </S.TableRow>
                </React.Fragment>
              )
            })}
      </tbody>

      <TwoButtonModal
        isVisible={showDeleteEvaluationConfirmModalVisible}
        title="Are you sure you want to delete this rating?"
        description="This rating is currently in progress, deleting it will remove all progress."
        onPressRight={async () => await deleteEvaluationFn(selectedEval)}
        onPressLeft={() => setShowDeleteEvaluationConfirmModalVisible(false)}
        rightButtonText="Delete"
        leftButtonText="Cancel"
        destructive
      />

      <TwoButtonModal
        isVisible={showResetEvaluationRatingRevisionConfirmModalVisible}
        title="Are you sure you want reset revision?"
        description="This will remove any info about the source evaluation and set the Revision number too 0."
        onPressRight={async () =>
          await resetEvaluationRatingRevisionFn(selectedEval)
        }
        onPressLeft={() =>
          setShowResetEvaluationRatingRevisionConfirmModalVisible(false)
        }
        rightButtonText="Yes, reset"
        leftButtonText="Cancel"
        destructive
      />
    </S.TableContainer>
  )
}

export default RTEvaluationsList
