import theme from '@app/ui/theme'
import Spacer from '@app/components/atoms/Spacer'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { useAxios } from '@common/hooks/useAxios'
import React, { useState, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import * as S from './styled'
import { DropDownMenu } from '@app/ui/dropdown-menu/DropDownMenu'
import firebase from 'firebase'
import {
  EvaluationDoc,
  SourceCourse,
} from '../../../pages/CompositeCoursePage/CompositeCoursePage'
import { RatingTemplate } from '../../../types/Templates'
import { useSnackbar } from '@app/snackbar'
import FeatherIcon from '@ovaeasy/react-native-vector-icons/Feather'
import Spinner from '@app/evaluation/components/Spinner'
import { format } from 'date-fns'
import { fontSize } from '@app/ui/fonts'
import DateTimeView from '../../atoms/DateTimeView'

interface Props {
  courses?: SourceCourse[]
  template?: RatingTemplate
  clubId: string
}

const MixedEvaluationModal = (props: Props) => {
  const axios = useAxios('/ratings/mixed/generate')
  const dispatch = useDispatch<any>()
  const [isGenerating, setIsGenerating] = useState(false)
  const [openSnackbar] = useSnackbar()
  const [sources, setSources] = useState(
    props.courses?.map(() => ({
      evaluationId: '',
      evaluationName: '',
      ratingDate: '',
    })) || []
  )
  const [nameOfForm, setNameOfForms] = useState('')
  const [formRatingDate, setFormRatingDate] = useState('')

  const updateSource = (courseIndex: number, evaluation: EvaluationDoc) => {
    console.log({ evaluation })
    setSources((prevSources) => {
      const newSources = [...prevSources]
      newSources[courseIndex] = {
        evaluationId: evaluation.id,
        evaluationName: evaluation.saveName || '',
        ratingDate: evaluation.ratingRevision.ratingDate || '',
      }
      return newSources
    })
  }

  const hideModal = () => dispatch({ type: 'SET_MODAL', params: null })

  const getHoleInfo = (holeId: string, index: number) => {
    const course = props.courses?.find((course) =>
      course.course.holes.some((hole) => hole.physicalID === holeId)
    )
    const hole = course?.course.holes.find((hole) => hole.physicalID === holeId)
    return { course, hole: { ...hole, source: index } }
  }

  const holes = useMemo(
    () => props.template?.holeIds.map(getHoleInfo),
    [props.template]
  )

  const holeEvals = useMemo(() => {
    const holeEvals = holes?.map((hole) => {
      return {
        holeId: hole.hole.physicalID,
        evaluationId: hole.course?.source
          ? sources[hole.course.source - 1].evaluationId
          : undefined,
      }
    })
    return holeEvals
  }, [holes, sources])

  const useFocus = () => {
    const htmlElRef = useRef<HTMLInputElement>(null)
    const setFocus = () => {
      if (htmlElRef.current) htmlElRef.current.focus()
    }

    return [htmlElRef, setFocus] as const
  }
  const [inputRef, setInputFocus] = useFocus()

  const submit = async () => {
    try {
      if (sources.length === 0) return

      if (nameOfForm === '') {
        setInputFocus()
        openSnackbar('Please enter a name for the form')
        return
      }

      if (props.template?.name === undefined || props.template?.name === '') {
        openSnackbar(
          'Composite course does not have a name. Please provide one'
        )
        return
      }

      setIsGenerating(true)
      const { data } = await axios.post(`/`, {
        clubId: props.clubId,
        holes: holeEvals,
        name: props.template?.name,
        templateId: props.template?.id,
        description: nameOfForm,
        dateOverride: formRatingDate,
      })
      const downloadFolder = (ref: string) => {
        const doc = firebase.storage().ref(ref)
        doc.getDownloadURL().then((url) => {
          const anchor = document.createElement('a')
          anchor.href = url
          anchor.download = document.title || ''
          anchor.click()
        })
      }

      downloadFolder(data)
      hideModal()
    } catch (error) {
      console.error('Error generating form:', error)
      openSnackbar('Error generating form')
    } finally {
      setIsGenerating(false)
    }
  }

  const CourseDropdown = ({
    course,
    index,
  }: {
    course: SourceCourse
    index: number
  }) => (
    <div key={'source' + course.source} style={{ marginRight: 8, width: 300 }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <span>
            <span style={{ fontWeight: 500 }}>
              Select rating {course.source}
            </span>{' '}
            <span style={{ fontWeight: 600 }}>{course.course.name}</span>
          </span>
        </div>
        <span style={{ fontSize: 12, fontWeight: 400, color: '#e1e1e1' }}>
          Required
        </span>
      </div>
      <Spacer height={12} />
      <DropDownMenu
        yAdj={10}
        width={280}
        value={sources[index].evaluationName || 'Select Rating'}
        relative
      >
        {course.evaluations.length ? (
          course.evaluations.map((evaluation) => (
            <S.Source
              key={evaluation.id}
              onClick={() => updateSource(index, evaluation)}
            >
              <span style={{ flexGrow: 1, textAlign: 'start' }}>
                {evaluation.saveName}
              </span>
              <DateTimeView
                dateString={evaluation?.ratingRevision.ratingDate}
                showTime={false}
              />
            </S.Source>
          ))
        ) : (
          <S.Source>No Ratings available</S.Source>
        )}
      </DropDownMenu>
    </div>
  )

  const RatingDateDropdown = () => {
    const isDisabled =
      sources.filter((source) => source.evaluationId === '').length > 0
    console.log({ isDisabled, sources })
    return (
      <div
        style={{
          marginRight: 8,
          width: 300,
          opacity: isDisabled ? 0.5 : 1,
        }}
      >
        <Spacer height={8} />
        <div
          style={{ display: 'flex', flexDirection: 'column', fontWeight: 500 }}
        >
          <span>Choose Rating Date to be displayed:</span>
        </div>
        <Spacer height={8} />
        <DropDownMenu
          disabledState={{
            disabled: isDisabled,
            message: 'Select Rating First',
          }}
          yAdj={10}
          width={280}
          value={
            formRatingDate !== ''
              ? format(new Date(formRatingDate), 'yyyy-MM-dd HH:mm')
              : 'Select Date'
          }
          relative
        >
          {sources.length &&
            sources.map((source, i) => (
              <S.Source
                key={i}
                onClick={() => setFormRatingDate(source.ratingDate)}
              >
                <span style={{ flexGrow: 1, textAlign: 'start' }}>
                  {source.evaluationName}
                </span>
                <DateTimeView dateString={source.ratingDate} showTime={false} />
              </S.Source>
            ))}
        </DropDownMenu>
      </div>
    )
  }

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>Generate Form</S.Title>
        <S.CloseButton onClick={hideModal}>X</S.CloseButton>
      </S.Header>
      <S.Line />

      {isGenerating ? (
        <S.SpinnerContainer>
          <Spinner />
          <S.SpinnerText> Generating...</S.SpinnerText>
        </S.SpinnerContainer>
      ) : (
        <S.Content>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
            <div
              style={{
                width: 280,
              }}
            >
              <label htmlFor="name" style={{ fontWeight: 500 }}>
                Forms name
              </label>
              <div style={{ fontSize: 12, color: '#e1e1e1' }}>Required</div>
              <S.Input
                ref={inputRef}
                value={nameOfForm}
                onChange={(e) => setNameOfForms(e.target.value)}
              />
            </div>

            <Spacer height={8} />

            <div style={{ display: 'flex' }}>
              {props.courses?.map((course, index) => (
                <CourseDropdown
                  key={course.course.id}
                  course={course}
                  index={index}
                />
              ))}
            </div>
          </div>
          <div>
            {sources.length > 1 && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <RatingDateDropdown />
              </div>
            )}
          </div>
          <Spacer height={8} />
          <div style={{ fontWeight: 500 }}>Holes</div>
          <S.HoleGrid>
            {holes?.map((hole, holeIndex) => (
              <S.RatingHole key={hole.hole.globalHoleNumber} selected={false}>
                <S.RatingHoleHeader>
                  <S.RatingHoleTitle>
                    Hole:{' '}
                    <span style={{ fontWeight: 800 }}>{holeIndex + 1}</span>{' '}
                    par: {hole.hole.par}
                  </S.RatingHoleTitle>
                  {holeEvals?.[holeIndex]?.evaluationId ? (
                    <S.CheckContainer>
                      <FeatherIcon name="check" size={18} color="white" />
                    </S.CheckContainer>
                  ) : null}
                </S.RatingHoleHeader>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '2px 16px 8px 16px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <S.RatingHoleHoleInfoText>
                      {hole.course?.course.name}{' '}
                      <span style={{ fontWeight: 800 }}>
                        {hole.hole.courseHoleNumber}
                      </span>
                    </S.RatingHoleHoleInfoText>
                  </div>
                </div>
              </S.RatingHole>
            ))}
          </S.HoleGrid>
          <RoundedButton
            disabled={!holeEvals || holeEvals.some((h) => !h.evaluationId)}
            title="Generate forms"
            width={'100%'}
            onPress={submit}
          />
        </S.Content>
      )}
    </S.Wrapper>
  )
}

export default MixedEvaluationModal
