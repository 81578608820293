import React, { useEffect } from 'react'
import { FBREF_COURSE_EVALUATIONS } from '@app/evaluation-core/refs'
import firebase from 'firebase'
import { Eval } from '@app/evaluation-core/types'

export function useRealTimeEvals() {
  const [realTimeEvals, setRealTimeEvals] = React.useState<
    Record<string, Eval> | undefined
  >(undefined)
  const [v1Evals, setV1Evals] = React.useState<
    Record<string, Eval> | undefined
  >(undefined)

  useEffect(() => {
    firebase
      .database()
      .ref(FBREF_COURSE_EVALUATIONS())
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val() as Record<string, Eval>
          setRealTimeEvals(data)
          const v1Evals = Object.entries(data).reduce((acc, [key, value]) => {
            if (value?.holeIds === undefined) {
              acc[key] = value
            }
            return acc
          }, {} as Record<string, Eval>)

          setV1Evals(v1Evals)
        }
      })

    firebase
      .database()
      .ref(FBREF_COURSE_EVALUATIONS())
      .on('value', (snapshot) => {
        if (snapshot.exists()) {
          setRealTimeEvals(snapshot.val())
        }
      })
  }, [])

  return { realTimeEvals, v1Evals }
}
