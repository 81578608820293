import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Eval } from '@app/evaluation-core/types'
import { State } from '@app/state/types'
import { useSelector } from 'react-redux'
import { EvaluationDoc } from '../../app/pages/CompositeCoursePage/CompositeCoursePage'
import { EvalStatus } from '@app/courses/domain/ratingStatus'
import { useRealTimeEvals } from '@common/hooks/useRealTimeEvals'
import { useAxios } from './useAxios'

export type EvalContainer = {
  rtEvalKey?: string // todo
  rtEval?: Eval
  fsEvalKey?: string // todo
  fsEval?: EvaluationDoc
}

export type FilteredEvals = {
  isLoading?: boolean
  combined?: EvalContainer[] // rt+fs
  rtOnly?: EvalContainer[] // realtime only
  fsOnly?: EvalContainer[] // firestore only
  v1?: EvalContainer[] // combined without holeids
  inProgress?: EvalContainer[] // !== Finalized
  finalized?: EvalContainer[] // === Finalized
}

export interface Issue {
  text: string
  mostLikelyWrong: string
}

export const getEvalStatus = (cont: EvalContainer) => {
  return cont?.fsEval?.status
}

export const getEvalRatingDate = (cont: EvalContainer): Date | undefined => {
  if (!cont?.rtEval?.ratingRevision?.ratingDate) return undefined
  return new Date(cont?.rtEval?.ratingRevision?.ratingDate)
}

export const getEvalTeamLeaderId = (cont: EvalContainer) => {
  return
}

export const getEvalTeamLeaderName = (cont: EvalContainer) => {
  return
}

export const getEvalRevision = (cont: EvalContainer): number => {
  if (!cont?.fsEval?.ratingRevision?.ratingRevision) return 0

  return cont.fsEval?.ratingRevision?.ratingRevision
}

export const getFinalizedAt = (cont: EvalContainer): Date | undefined => {
  if (!cont?.fsEval?.finalizedAt) return undefined

  return new Date(cont?.fsEval?.finalizedAt)
}

export const getLastFinalized = (
  evals: FilteredEvals,
  courseId: number
): Date | undefined => {
  const ret = evals.combined
    ?.filter(
      (x) =>
        x.fsEval?.status === EvalStatus.FINALIZED &&
        x.fsEval?.clubId === courseId
    )
    .sort((a, b) => {
      if (!a?.fsEval?.finalizedAt) return 1
      if (!b?.fsEval?.finalizedAt) return -1

      return (
        new Date(a.fsEval?.finalizedAt).getTime() -
        new Date(b.fsEval?.finalizedAt).getTime()
      )
    })
    .find((x) => true)

  if (!ret?.fsEval?.finalizedAt) return undefined

  return new Date(ret?.fsEval?.finalizedAt)
}

const useFilteredEvaluations = (): FilteredEvals => {
  const evalAxios = useAxios('/evaluations')
  const evaluations = useSelector(
    (state: State) => state.firebase.data.evaluations
  ) as FilteredEvals
  const [fireStoreEvals, setFireStoreEvals] = React.useState<EvaluationDoc[]>(
    []
  )

  const { realTimeEvals, v1Evals } = useRealTimeEvals()

  const getAllFSEvaluation = async () => {
    try {
      const { data } = await evalAxios.get<EvaluationDoc[]>(`/import/all`)

      return data
    } catch (error) {
      console.error('Error fetching evaluations:', error)
      return []
    }
  }

  useEffect(() => {
    getAllFSEvaluation().then((evals) => {
      setFireStoreEvals(evals)
    })
  }, [])

  const getFSEvaluation = async (evaluationId: string) => {
    try {
      const { data } = await evalAxios.get<EvaluationDoc[]>(
        `/import/${evaluationId}`
      )
      return data
    } catch (error) {
      console.error('Error fetching evaluations:', error)
      return []
    }
  }

  if (!realTimeEvals || !fireStoreEvals) return { isLoading: true }

  const allEvals = Object.entries(realTimeEvals)
    .map(([evalId, rtEval]): EvalContainer => {
      //EvalID is the realTime KEY ID, the id in evaluation is the property id. SHould be the same BUT IS NOT GARANTEED since the import v1 function did not replace old property id
      const fsEval = fireStoreEvals.find((e) => e.id === evalId)

      return {
        rtEval: rtEval,
        rtEvalKey: evalId,
        fsEval: fsEval as EvaluationDoc,
        fsEvalKey: 'N/A atm',
      }
    })
    .sort((a, b) => {
      if (!a?.rtEval?.clubName && !b?.rtEval?.clubName) return 0
      if (!a?.rtEval?.clubName) return 1
      if (!b?.rtEval?.clubName) return -1

      return a.rtEval.clubName.localeCompare(b.rtEval.clubName)
      if (!a?.rtEval?.savedAt && !b?.rtEval?.savedAt) return 0
      if (!a?.rtEval?.savedAt) return -1
      if (!b?.rtEval?.savedAt) return 1

      return (
        new Date(String(a.rtEval?.savedAt)).getTime() -
        new Date(String(a.fsEval?.savedAt)).getTime()
      )
    })
  const combined = allEvals
    .filter((x) => {
      return x.rtEval && x.fsEval
    })
    .sort((a, b) => {
      if (!a?.fsEval?.createdAt && !b?.fsEval?.createdAt) return 0
      if (!a?.fsEval?.createdAt) return 1
      if (!b?.fsEval?.createdAt) return -1

      return (
        new Date(String(a.fsEval?.createdAt)).getTime() -
        new Date(String(a.fsEval?.createdAt)).getTime()
      )
      /*
      if (!a?.rtEval?.clubName && !b?.rtEval?.clubName) return 0
      if (!a?.rtEval?.clubName) return 1
      if (!b?.rtEval?.clubName) return -1

      return a.rtEval.clubName.localeCompare(String(b.rtEval.clubName))
      if (!a?.rtEval?.savedAt && !b?.rtEval?.savedAt) return 0
      if (!a?.rtEval?.savedAt) return -1
      if (!b?.rtEval?.savedAt) return 1

      return (
        new Date(String(a.rtEval?.savedAt)).getTime() -
        new Date(String(a.fsEval?.savedAt)).getTime()
      )*/
    })
  const rtOnly = allEvals.filter((x) => {
    return !x.fsEval
  })
  const filteredEvals = {
    isLoading: false,
    combined: combined,
    rtOnly: rtOnly,
    fsOnly: allEvals.filter((x) => {
      return !x.rtEval
    }),
    v1: allEvals.filter((x) => {
      return x.rtEval?.holeIds === undefined
    }),
    inProgress: combined.filter((x) => {
      return x.rtEval?.status !== EvalStatus.FINALIZED
    }),
    finalized: combined.filter((x) => {
      return x.rtEval?.status === EvalStatus.FINALIZED
    }),
  }

  return filteredEvals
}

export default useFilteredEvaluations
