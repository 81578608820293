import theme from '@app/ui/theme'
import React from 'react'
import { FaAngleDown, FaAngleUp, FaBars } from 'react-icons/fa'
import Icon from '@ovaeasy/react-native-vector-icons/FontAwesome'

import { CSSObjectWithLabel } from 'react-select'
import styled from 'styled-components'
import { css } from 'styled-components'
import S from 'styled-components/native'

const OL_DEFAULTWIDTH = 210

interface MenuOverlayProps {
  transform: string
  x?: number | string
  y?: number | string
  xAdj?: number | string
  yAdj?: number | string
  width: number
  zIndex?: number
  tablet?: boolean
  relative?: boolean
}

export const ClearIndicatorDiv = styled.div<{
  indicatorStyles: CSSObjectWithLabel
}>`
  // @ts-ignore
  ${(props) =>
    css`
      ${props.indicatorStyles as any}
    `}
  padding: 0px;
  padding-right: 8px;
`

export const MenuOverlay = styled.div`
  width: ${(props: MenuOverlayProps) =>
    props.width ? props.width : OL_DEFAULTWIDTH}px;
  background: ${theme.colors.white};
  padding: 20px;
  margin-top: 50px;
  border-radius: 16px;
  position: fixed;
  z-index: ${(props: MenuOverlayProps) =>
    props.zIndex ? props.zIndex : '100'};
  user-select: none;
  transform: ${(props: MenuOverlayProps) => props.transform};
  box-shadow: 0 0px 40px 0 ${theme.colors.dark1};
  & > div:last-child {
    border-bottom: 0;
  }
  top: ${(props: MenuOverlayProps) => (props.y ? props.y : 70)}px;
  margin-left: ${(props: MenuOverlayProps) =>
    props.xAdj ? props.xAdj + 'px' : 0 + 'px'};
  margin-top: ${(props: MenuOverlayProps) =>
    props.yAdj ? props.yAdj + 'px' : 0 + 'px'};

  ${({ relative, x }) => (relative && x ? x + 'px' : 0 + 'px')}
`

interface DropDownProps {
  ref: React.RefObject<HTMLDivElement>
  style?: any
  backgroundColor?: string
  color?: string
  rounded?: boolean
  useShadow: boolean
}

interface DropDownSubMenuProps {
  style?: React.CSSProperties
  backgroundColor?: string
  color?: string
  rounded?: boolean
  useShadow: boolean
  width?: number
}

export const DropDown = styled.div<DropDownProps>`
  background-color: ${(props) => props.backgroundColor ?? theme.colors.white};
  box-shadow: ${(props) =>
    props.useShadow ? `0 0px 4px 0 ${theme.colors.dark1};` : `none`}
  height: 40px;
  line-height: 40px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: ${(props) => (props.rounded ? theme.borderRadius : 5)}px;
  display: flex;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 500;
  color: ${(props) => props.color ?? '#333'};
  & > * {
    height: 40px;
  }
  margin-top: 0px;
  cursor: pointer;
  user-select: none;
  &:active {
    opacity: 0.7;
  }
  ${(props) => props.style}
`

export const DropDownIcon = styled(Icon)`
  height: 40px;
  margin-left: 10px;
  display: flex;
  align-items: center;
`

interface DropDownMenuItemProps {
  disabled?: boolean
}

export const DropDownMenuItem = S.TouchableOpacity`
  position: relative;
  display: block;
  padding: 10px;
  font-size: 15px;
  opacity: ${(props: DropDownMenuItemProps) => (props.disabled ? '0.6' : 1)};
  border-bottom-width: 1;
  border-bottom-color: ${theme.colors.light};
  padding-left: 14px;
  padding-right: 14px;
  line-height: 40px;
`

export const DropDownSubMenuContainer = styled.div<DropDownSubMenuProps>`
  position: absolute;
  display: block;
  padding: 5px;
  left: 100%;
  top: 0;
  width: ${(props) => (props.width ? props.width : 200)}px;
  line-height: 30px;
  padding-left: 14px;
  padding-right: 14px;
  background-color: ${(props) => props.backgroundColor ?? theme.colors.white};
  border-radius: ${(props) => (props.rounded ? theme.borderRadius : 5)}px;
  box-shadow: ${(props) =>
    props.useShadow ? `0 0px 4px 0 ${theme.colors.dark1};` : `none`};

  & > div:last-child {
    border-bottom: 0;
  }
`

export const DropDownMenuSubItem = S.TouchableOpacity`
  display: block;
  padding: 10px;
  background-color: ${theme.colors.white};
  border-bottom-width: 1;
  border-bottom-color: ${theme.colors.light};
  font-size: 15px;
  opacity: ${(props: DropDownMenuItemProps) => (props.disabled ? '0.6' : 1)};
`

// For some reason iheritance is not working
export const BorderlessDropDownMenuItem = S.TouchableOpacity`
  display: block;
  padding: 10px;
  font-size: 15px;
  opacity: ${(props: DropDownMenuItemProps) => (props.disabled ? '0.6' : 1)};
  border: none;
`

// For some reason iheritance is not working
export const AdminTitle = styled.div`
  margin-top: 20px;
  font-weight: 200;
  text-align: left;
  font-size: 0.8em;
  line-height: 30px;
`

export const DropDownMenuDiv = S.View`
  padding: 10px;
  border-bottom-width: 1;
  border-bottom-color: ${theme.colors.light};
  font-size: 15px;
`

export const DropDownTitleContainer = styled.div`
  margin-left: 10px;
`
export const DropDownIconContainer = styled.div<{ noArrow: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-left: ${(props) => (!props.noArrow ? 0 : 10)}px;
`
