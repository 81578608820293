import { AccountType, User } from '@app/auth/types'
import {
  Course,
  CourseEvaluation,
  EvaluationState,
  EvaluationUser,
  EvaluationUserView,
  Measurement,
  GrassType,
  TeamRole,
  Eval,
} from './types'
import { clearEvaluation, initEvaluation } from './actions/evaluationActions'
import { initialEvaluation, userView } from './initialValues'

import { clearAdjustments } from './actions/adjustmentsActions'
import fb from '@app/firebase'
import { navigate } from '@app/navigation'
import store from '@app/state'
import theme from '@app/ui/theme'
import { fetch } from '../../common/fetch'
import getEnv from '@app/environments/getEnv'
import { attachInterceptors } from '@common/hooks/useAxios'
import { LRCourse } from '../types/Clubs'
import Axios from 'axios'
import { RatingRevision } from '../types/Templates'
import { EvaluationDoc } from '../pages/CreateRatingPage/CreateRatingPage'
import { getToken } from '@common/hooks/useApi'
import { apiClient } from '../../common/api/client'
import { ManualVersion } from '../types/General'
import { EvalStatus } from '@app/courses/domain/ratingStatus'

export function shadeColor(color: string, percent: number) {
  let R: number = parseInt(color.substring(1, 3), 16)
  let G: number = parseInt(color.substring(3, 5), 16)
  let B: number = parseInt(color.substring(5, 7), 16)

  R = parseInt(((R * (100 + percent)) / 100).toString())
  G = parseInt(((G * (100 + percent)) / 100).toString())
  B = parseInt(((B * (100 + percent)) / 100).toString())

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16)
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16)
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16)

  return '#' + RR + GG + BB
}

type CreateEvaluationProps = {
  userId: string
  clubId: number
  userEmail: string
  branchId: string
  holeIds: string[]
  ratingRevision?: RatingRevision
  isAdmin?: boolean
}

export const createEvaluation = async ({
  clubId,
  userId,
  userEmail,
  branchId,
  holeIds,
  ratingRevision,
  isAdmin,
}: CreateEvaluationProps) => {
  const color = TEAM_COLORS[Math.floor(Math.random() * TEAM_COLORS.length)]

  const initialValue = initialEvaluation(
    {
      userId,
      role: isAdmin ? TeamRole.SYSTEM_ADMIN : TeamRole.LEADER,
      view: { ...userView(), color, email: userEmail },
    },
    branchId,
    holeIds,
    clubId,
    ratingRevision || null
  )

  const evaluation = await fb.ref('evaluations').push(initialValue)
  const key = evaluation.key

  return key
}

const TEAM_COLORS = [
  '#78E5E1',
  '#78E58B',
  '#9678E5',
  '#E578BD',
  '#C66060',
  '#CFDF2A',
]

export const addTeamMember = async (
  key: string,
  userId: string | null,
  email: string | null,
  role: number
) => {
  const color = TEAM_COLORS[Math.floor(Math.random() * TEAM_COLORS.length)]

  return await fb.ref(`evaluations/${key}/users/${userId}`).set({
    role,
    userId,
    view: { ...userView(), color, email },
  })
}

export const removeTeamMember = async (key: string, userId: string) =>
  await fb.ref(`evaluations/${key}/users/${userId}`).remove()

// export const acceptInvitation = async (
//   invitation: Invitation,
//   realId: string,
//   email: string | null
// ) => {
//   const evaluationId = invitation.evaluationId
//   const userId = invitation.userId

//   localStorage.removeItem('invitation')

//   const evaluation = await fb.ref(`evaluations/${evaluationId}`).get()
//   const evalObj: CourseEvaluation = evaluation.val()
//   const usersInEval = Object.keys(evalObj.users)

//   if (!usersInEval.includes(userId)) {
//     return
//   }

//   await addTeamMember(evaluationId, realId, email, TeamRole.EVALUATOR)

//   await fb.ref(`evaluations/${evaluationId}/users/${userId}`).remove()
// }

export const startEvalFromInvite = async (courseId: string) => {
  // const course = await fb.firestore().collection('courses').doc(courseId).get()
  // startEvaluation(courseData)
}

export const getCurrentUser = () => store.getState()?.user as User

export const OPEN_EVALUATION_FAILURE = {
  not_member: 'Your account is not registered for this course evaluation',
  not_member_revision:
    'Only users who were a part of the evaluation can create a revision.',
  not_authorized: 'Only a team leader can start an evaluation.',
  not_evaluator: 'Your account is not permitted to view evaluations.',
}

const deleteFirebaseObjects = (collection: string) =>
  fb.ref(collection).once('value', async (snapshot) => {
    const objects = snapshot.val()
    if (objects === null) {
      return
    }

    const promises = Object.keys(objects).map((key: string) => {
      fb.ref(`${collection}/${key}`).remove()
    })
    return Promise.all(promises)
  })

export const __DEBUG__clearAllEvaluations = async () => {
  await deleteFirebaseObjects('evaluations')
  await deleteFirebaseObjects('adjustments')
}

export const startEvaluation = async (
  clubId: number,
  branchId: string,
  holeIds: string[],
  ratingRevision?: RatingRevision,
  evaluation?: EvaluationDoc,
  copyOriginalId?: string | null
) => {
  const user = getCurrentUser()
  const dbDoc = evaluation
    ? await fb.database().ref(`evaluations/${evaluation.id}`).get()
    : undefined
  const dbEval = dbDoc?.val()
  if (!dbEval) {
    const isAdminUser =
      user.accountType === AccountType.Interal ||
      user.accountType === AccountType.SGFTeamLeader

    const key = await createEvaluation({
      userEmail: user.email,
      userId: user.id.toString(),
      branchId,
      holeIds,
      clubId,
      ratingRevision,
      isAdmin: isAdminUser,
    })

    store.dispatch(
      initEvaluation({
        currentId: key,
      })
    )

    if (copyOriginalId) {
      return navigate('CourseEvaluation', {
        screen: 'Overview',
        params: { copyOriginalId },
      })
    }

    return navigate('CourseEvaluation', {
      screen: 'Overview',
    })
  }

  const key = evaluation?.id

  // Finalized evaluation
  if (evaluation?.status === EvalStatus.FINALIZED) {
    const isAdminUser =
      user.accountType === AccountType.Interal ||
      user.accountType === AccountType.SGFTeamLeader

    const userIsNotInEval = !Object.keys(evaluation.users).includes(
      user.id.toString()
    )

    const newId = await createEvaluation({
      userEmail: user.email,
      userId: user.id.toString(),
      branchId,
      holeIds,
      clubId,
      ratingRevision,
      isAdmin: isAdminUser,
    })

    try {
      await apiClient('/evaluations').post(`/import/${newId}/${key}`)
    } catch (err) {
      console.log(err)
    }

    store.dispatch(
      initEvaluation({
        currentId: newId,
      })
    )

    if (copyOriginalId) {
      return navigate('CourseEvaluation', {
        screen: 'Overview',
        params: { copyOriginalId },
      })
    }

    return navigate('CourseEvaluation', { screen: 'Preparation' })
  }

  if ((evaluation?.status === 1 || evaluation?.status === 2) && evaluation) {
    const evalUsers = await fb
      .ref(`evaluations/${evaluation.id}/users`)
      .once('value')

    const users: { [key: string]: EvaluationUser } = evalUsers.val()

    const isAdminUser =
      user.accountType === AccountType.Interal ||
      user.accountType === AccountType.SGFTeamLeader

    const noIdMatch = Object.keys(users).every((userId) => user.id !== +userId)
    const includesUserEmail = Object.values(users).some(
      (evalUser) => evalUser.view.email === user.email
    )

    const hasUserInvite = noIdMatch && includesUserEmail
    if (hasUserInvite) {
      const matchedUser = Object.entries(users).find(
        ([key, evalUser]) => evalUser.view.email === user.email
      )
      if (matchedUser) {
        await addTeamMember(
          evaluation.id,
          user.id.toString(),
          user.email,
          TeamRole.EVALUATOR
        )
        const tempId = matchedUser[0]
        await fb.ref(`evaluations/${evaluation.id}/users/${tempId}`).remove()
      }
    }
    const userIsNotInEval = !Object.keys(users).includes(user.id.toString())
    if (userIsNotInEval && !hasUserInvite && !isAdminUser) {
      throw 'not_member'
    }

    if (isAdminUser && userIsNotInEval) {
      await addTeamMember(
        evaluation.id,
        user.id.toString(),
        user.email,
        user.accountType === AccountType.Interal
          ? TeamRole.SYSTEM_ADMIN
          : TeamRole.EVALUATOR
      )
    }

    if (!isAdminUser && userIsNotInEval) {
      return
    }

    store.dispatch(
      initEvaluation({
        currentId: evaluation.id,
        evaluation: dbEval,
      })
    )

    if (copyOriginalId) {
      return navigate('CourseEvaluation', {
        screen: 'Preparation',
        params: { copyOriginalId },
      })
    }

    return navigate('CourseEvaluation', {
      screen: 'Preparation',
    })
  }
}

export const viewEvaluationFn = async (evaluation?: EvaluationDoc) => {
  if (!evaluation) {
    return
  }

  const dbDoc = evaluation
    ? await fb.ref(`evaluations/${evaluation.id}`).get()
    : undefined

  const dbEval = dbDoc?.val()
  if (!dbEval) {
    return
  }
  const user = getCurrentUser()
  const users = dbEval.users

  const isAdminUser =
    user.accountType === AccountType.Interal ||
    user.accountType === AccountType.SGFTeamLeader

  const isFinalized = evaluation.status === EvalStatus.FINALIZED

  const userIsNotInEval = !Object.keys(users).includes(user.id.toString())
  if (userIsNotInEval && !isAdminUser && !isFinalized) {
    throw 'not_member'
  }

  if (isAdminUser && userIsNotInEval) {
    await addTeamMember(
      evaluation.id,
      user.id.toString(),
      user.email,
      TeamRole.SYSTEM_ADMIN
    )
  }

  if (!isAdminUser && userIsNotInEval && isFinalized) {
    await addTeamMember(
      evaluation.id,
      user.id.toString(),
      user.email,
      TeamRole.READ_ONLY
    )
  }

  store.dispatch(
    initEvaluation({
      evaluation: dbEval,
      currentId: evaluation?.id,
    })
  )
  return navigate('CourseEvaluation')
}
export const deleteEvaluation = async (evaluationId: string) => {
  await fb.ref(`evaluations/${evaluationId}`).remove()
  await fb.ref(`adjustments/${evaluationId}`).remove()
  await fb.firestore().collection('evaluations').doc(evaluationId).delete()
}

export const updatePreparation = async (evaluation: CourseEvaluation) => {
  const { currentId } = getEvaluationState()
  await fb.ref(`evaluations/${currentId}`).update(evaluation)
}

export const getEvaluationState = () => {
  const state = store.getState()
  const evaluationState = state.evaluation as EvaluationState
  return {
    ...evaluationState,
    view: evaluationState?.evaluation.users[state.user.id]
      .view as EvaluationUserView,
  }
}

export const updateEvaluationDate = async (currentId: string, date: Date) => {
  await fb.ref(`evaluations/${currentId}`).update({
    evaluationDate: date,
  })
}

export const updateEvaluationDesciption = async (description: string) => {
  const { currentId } = getEvaluationState()
  await fb.ref(`evaluations/${currentId}`).update({
    description,
  })
}

export const updateEvaluationManualVersion = async (
  manualVersion: ManualVersion
) => {
  const { currentId } = getEvaluationState()
  await fb.ref(`evaluations/${currentId}`).update({
    manualVersion,
  })
}

export const updateEvaluationRatingRevision = async (
  ratingRevision: number
) => {
  const { currentId } = getEvaluationState()
  await fb.ref(`evaluations/${currentId}/ratingRevision`).update({
    ratingRevision,
  })

  const docRef = await fb
    .firestore()
    .collection('evaluations')
    .doc(currentId)
    .get()

  const rev = docRef.data().ratingRevision

  rev.ratingRevision = ratingRevision

  await fb
    .firestore()
    .collection('evaluations')
    .doc(currentId)
    .update({ ratingRevision: rev })
}

export const submitPreparation = async () => {
  const { courseId, currentId, evaluation } = getEvaluationState()
  if (evaluation.isMixed || evaluation.status > 1) {
    return
  }
  await fb.ref(`evaluations/${currentId}`).update({
    status: 2,
  })
}

export const submitEvaluation = async (user: User, fileName: string) => {
  //TODO: Hacky solution to prevent crash due to hook order/length gets messed upp. May be a better solution to this.
  navigate('ClubManagement', { screen: 'CreateRating', wait: true })
  const { currentId } = getEvaluationState()
  await fetch.post(
    `${getEnv().api}/evaluations/save`,
    {
      evaluationId: currentId,
      finalize: true,
      fileName,
    },
    {
      headers: {
        authorization: getToken('access', user),
        refreshtoken: getToken('refresh', user),
      },
    }
  )
  navigate('ClubManagement', { screen: 'CreateRating', wait: false })
}

export const updateEvaluationComment = async (
  currentId: string,
  comment: string
) => {
  await fb.ref(`evaluations/${currentId}`).update({
    comment: comment,
  })
}

export const resetCourseEvaluation = async (courseId: string) => {
  return null
}

export const clearEvaluationData = async (arg = 'nav') => {
  const { courseId, currentId } = getEvaluationState()

  await fb.ref(`evaluations/${currentId}`).remove()
  await fb.ref(`adjustments/${currentId}`).remove()

  // This solves an issue with listeners not being removed
  // should be fixed elsewhere but it's a necessary fix
  // due to the complexity of the implementation
  setTimeout(() => {
    clearAdjustments()(store.dispatch)
    clearEvaluation()(store.dispatch)
  }, 1000)

  return navigate('Courses', null)
}
export const clearMixedEvaluationData = async (arg = 'nav') => {
  const { currentId } = getEvaluationState()

  await fb.ref(`evaluations/${currentId}`).remove()
  await fb.ref(`adjustments/${currentId}`).remove()

  // This solves an issue with listeners not being removed
  // should be fixed elsewhere but it's a necessary fix
  // due to the complexity of the implementation
  setTimeout(() => {
    clearAdjustments()(store.dispatch)
    clearEvaluation()(store.dispatch)
  }, 1000)

  return navigate('Courses', null)
}

export const clearEvaluationState = () => {
  clearEvaluation()(store.dispatch)
  clearAdjustments()(store.dispatch)
}

export const resetEvaluationRatingRevision = async (key: string) => {
  console.log(key)
  const fsRatingRevision = {
    parentEvaluation: key,
    ratingDate: null,
    ratingRevision: 0,
    rootEvaluation: key,
  }

  /*
  await fb
    .database()
    .ref(`evaluations/${key}/ratingRevision`)
    .set(ratingRevision)*/
  await fb
    .firestore()
    .collection('evaluations')
    .doc(key)
    .update({ ratingRevision: fsRatingRevision })
}

export const getRealTimeEvaluation = async (
  evaluationId: string
): Promise<Eval | null> => {
  if (!evaluationId) return null

  const snapshot = await fb.ref(`evaluations/${evaluationId}`).get()

  return snapshot.val()
}
